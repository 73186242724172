import react from '../../assets/react-native.png';

import '../../styles/days.scss';

const Eighteen = () => (
  <div className='days full-image rounded'>
    <img src={react} alt='react native logo' />
  </div>
);

export default Eighteen;
