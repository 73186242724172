import redux from '../../assets/redux.png';

import '../../styles/days.scss';

const TwentyThree = () => (
  <div className='days full-image'>
    <img src={redux} alt='redux' />
  </div>
);

export default TwentyThree;
