import styled from '../../assets/styled_components.svg';

import '../../styles/days.scss';

const Fifteen = () => (
  <div className='days full-image'>
    <img src={styled} alt='styled component' />
  </div>
);

export default Fifteen;
