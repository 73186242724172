import design from '../../assets/karenying-design-2.png';

import '../../styles/days.scss';

const Twenty = () => (
  <div className='days full-image rounded'>
    <img src={design} alt='neural net' />
  </div>
);

export default Twenty;
