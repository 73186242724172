import backtotop from '../../assets/backtotop.png';

import '../../styles/days.scss';

const Eleven = () => (
  <div className='days Eleven'>
    <img src={backtotop} alt='back to top button' />
  </div>
);

export default Eleven;
