import slack from '../../assets/slack.png';

import '../../styles/days.scss';

const Six = () => (
  <div className='days full-image'>
    <img src={slack} alt='slack logo' />
  </div>
);

export default Six;
