import esc from '../../assets/esc.png';

import '../../styles/days.scss';

const TwentyFour = () => (
  <div className='days full-image'>
    <img src={esc} alt='redux' />
  </div>
);

export default TwentyFour;
