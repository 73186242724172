import julia from '../../assets/julia.svg';

import '../../styles/days.scss';

const Four = () => (
  <div className='days full-image'>
    <img src={julia} alt='julia logo' />
  </div>
);

export default Four;
